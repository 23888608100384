<template>
  <div class="home" id="home">
    <TopNav></TopNav>
    <div class="first-1">
      <div class="block">
        <el-carousel height="700px" arrow="never">
          <el-carousel-item v-for="(p,q) in 1" :key="q">
            <!--            <div class="carousel-box"></div>-->
            <img style="object-fit:cover;" src="../static/img/home/home-1.png" height="700px" width="100%"/>
          </el-carousel-item>
        </el-carousel>
        <div class="top-bg-all">
          <div class="top-bg">
            <div class="top-bg-text">
              <div class="top-bg-text-1">
                <img src="../static/img/home/Solution.png" height="69" width="159"/>
              </div>
              <div class="top-bg-text-2">
                <p>区域一体化中医人工智能共享服务平台</p>
                <div style="line-height: 28px;margin-top: 10px;">
                  国医经方人工智能辅助诊断平台，由医疗信息人工智能技术专家团队与国医专家团队两大核心团队领衔研发，是基于中医大数据和人工智能技术、中医辨证分析为核心的区域一体化辅助诊断平台，能够根据患者的四诊信息准确模拟名老中医的辨证思维方式，让基层医师快速融入名老中医的辨证思维之中，从而快速提升基层中医整体服务能力。


                  <!--                  经方云中医智能云服务平台是经方云科技创新研发的基于中医大数据和人工智能技术、中医辨证分析为核心的区域一体化辅助诊断平台。平台以中医院为中心，县、乡、村一体化部署，融合智能诊断、常见病诊断、远程会诊、远程教育、知识库和共享药房六大核心引擎，以“互联网+中医”技术实现中医优质资源下沉，快速提升基层中医诊疗水平，让老百姓足不出村即可享受到高质量的中医药服务、中药配送-->
<!--                  到家服务、健康管理服务。-->
                </div>
              </div>
              <div style="padding-top: 93px;">
                <img @click="goScheme" src="../static/img/home/lookinf.png" height="60" width="180"/>
              </div>
            </div>
          </div>
          <div class="top-bg-2">
            <img src="../static/img/home/foot-img.png" height="44" width="44"/>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 274px;width: 100%;background-color: #F5F5F5"></div>
    <div class="third">
      <div class="fy_center_box">
        <div><img src="../static/img/home/middle-1-img.png" height="76" width="253"/></div>
        <div class="center_box">
          <div v-for="(item, i) in middleData" :key="i" @click="goYun()"
               :class="item.code === 1?'third-box':'third-box1'">
            <img :src="item.url"/>
            <p class="third-box-txt">{{ item.value }}</p>
            <p class="third-box-txt1">{{ item.val }}</p>
          </div>
        </div>
      </div>
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="four">
      <!--      <div style="width: 1560px;">-->
      <div>&nbsp;</div>
      <el-card class="card" shadow="always">
        <img class="card-1" src="../static/img/home/four-1.png" height="400" width="280"/>
        <div class="card-line"></div>
        <div style="margin-left: 182px">
          <div style="width: 880px">
            <div class="four-box four-box1">
              <div style="display: flex">
                <img src="../static/img/home/four-2.png"/>
                <div class="four-box-txt">
                  <p class="four-box-txt1">技术优势</p>
                  <div class="four-box-txt2">专业的团队</div>
                </div>
              </div>
            </div>
            <div class="four-box four-box2">
              <div style="display: flex">
                <img src="../static/img/home/four-3.png"/>
                <div class="four-box-txt">
                  <p class="four-box-txt1">专业</p>
                  <div class="four-box-txt2">权威专家团队</div>
                </div>
              </div>
            </div>
            <div class="four-box four-box3">
              <div style="display: flex">
                <img src="../static/img/home/four-4.png"/>
                <div class="four-box-txt">
                  <p class="four-box-txt1">实验室</p>
                  <div class="four-box-txt2">知名机构合作科研实验室</div>
                </div>
              </div>
            </div>
            <div class="four-box four-box4">
              <div style="display: flex">
                <img src="../static/img/home/four-5.png"/>
                <div class="four-box-txt">
                  <p class="four-box-txt1">精准度保障</p>
                  <div class="four-box-txt2">20多名权威专家医师反复临床试验，反馈修改，保障结果精准度。
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </el-card>
      <!--      </div>-->

    </div>
    <div class="node-img">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="five">
      <div class="fy_center_box">
        <img style="margin-bottom: 20px" src="../static/img/home/mimddle-2-1.png" height="76" width="159"/>
        <div class="inf">
          <div @click="toDetail(infoData1.id)">
            <el-card class="inf-card" shadow="always">
              <img :src="infoData1.img" height="315" width="560"/>
              <div class="inf-card-time">
                <div class="inf-card-time1">{{ infoData1.time ? infoData1.time.substr(0, 7) : '--' }}</div>
                <div class="inf-card-time2">{{ infoData1.time ? infoData1.time.substr(8, 9) : '--' }}</div>

              </div>
              <p class="inf-card-b1">{{ infoData1.title }}</p>
              <div class="inf-card-b2"> {{ infoData1.info }}</div>
            </el-card>
          </div>
          <div class="inf-r">
            <div class="inf-r1" v-for="(item,i) in infoData2" :key="i" @click="toDetail(item.id)">
              <div class="inf-r1-time">
                <div class="inf-r1-time1">{{ item.time ? item.time.substr(0, 7) : '--' }}</div>
                <div class="inf-r1-time2">{{ item.time ? item.time.substr(8, 9) : '--' }}</div>
              </div>
              <div class="inf-r1-txt">
                <div class="inf-r1-txt1">{{ item.title }}</div>
                <div class="inf-r1-txt2">{{ item.info }}</div>
              </div>
            </div>
          </div>

        </div>
        <div style="width: 100%;text-align: center;margin-top: 30px" @click="goinf()">
          <img src="../static/img/home/lookinf.png" height="60" width="180"/>
        </div>
      </div>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="last">
      <div class="fives">
        <div class="fy_center_box">
          <img style="margin-bottom: 50px" src="../static/img/home/friend-img.png" height="76" width="159"/>
        </div>
        <div class="fy_center_box">
          <div class="friends-box">
            <el-card class="friends-card" shadow="always">
              <img src="../static/img/home/hosp-2.jpg" height="180" width="360"/>
              <div class="friends-card-b1">北京中医药大学东直门医院</div>
            </el-card>
            <el-card class="friends-card friends-card-mid" shadow="always">
              <img src="../static/img/home/friend-img2.png" height="180" width="360"/>
              <div class="friends-card-b1">南京中医药大学附属医院</div>
            </el-card>
            <el-card class="friends-card" shadow="always">
              <img src="@/static/image/logo/xz.png" height="180" width="360"/>
              <div class="friends-card-b1">西藏藏医药大学附属医院</div>
            </el-card>
          </div>
          <div>
            <el-carousel :autoplay="false" arrow="always" height="240px">
              <el-carousel-item v-for="(item,i) in lists" :key="i">
                <div class="bottom-img">
                  <div class="slide_img" v-for="(l,m) in item" :key="m">
                    <img :src="require('@/static/image/logo/'+l+'.png')"/>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNav from '@/components/Nav/TopNav.vue'
import Footer from '@/components/Footer/Footer.vue'
// import Footer1 from "./../components/Footer/Footer1.vue";

export default {
  name: 'Index',
  data() {
    return {
      lists: [
        [1, 2, 3, 4, 5, 6],
        [7, 8, 9, 10]
      ],
      value: 1,
      middleData: [
        {url: require('../static/img/home/middle-1-1.png'), value: '智能诊断', val: '辨证论治，千人千方\n', code: 1},
        {url: require('../static/img/home/middle-1-2.png'), value: '慢病防治', val: '常见疾病快速诊疗', code: 1},
        {url: require('../static/img/home/middle-1-3.png'), value: '远程会诊', val: '定期举办线上会诊、远程会诊支持', code: 1},
        {url: require('../static/img/home/middle-1-4.png'), value: '共享药房', val: '资源集中管控，标准化管理，降低风险', code: 1},
        {url: require('../static/img/home/middle-1-5.png'), value: '知识库', val: '药典、方剂、图书、疾病、针灸、医案，百万知识库', code: 1},
        {url: require('../static/img/home/middle-1-6.png'), value: '远程教育', val: '名医名家常用方药的辨证论治心得和用药用量原理解析', code: 1},
        {
          url: require('../static/img/home/middle-1-7.png'),
          value: '健康管理APP',
          val: '膳食、资讯、课程、健康管理 ，随时随地掌握全家人的健康状态',
          code: 1
        },
        {url: require('../static/img/home/lookinf.png'), value: '', val: '', code: 0},
      ],
      typeData: {},
      typeData1: {},
      params: {
        page: 1,
        limit: 10,
        type: 0 //行业0 公司1
      },
      infoData1: {},
      infoData2: []
    }
  },
  created() {
    this.getTypeData()
  },
  methods: {
    //请求数据
    getTypeData() {
      this.$Api
          .getTopList()
          .then(res => {
            const result = res.data || []
            this.typeData = result[0] || {}
            this.typeData1 = result[1] || {}
          })
      this.$Api.selectlist(this.params).then(res => {
        if (res.data.length > 0) {
          this.infoData1 = res.data[0]
          console.log(this.infoData1)
          if (res.data.length > 1) {
            this.infoData2 = res.data.slice(1, 4)
          }
        }
      })
          .catch(() => {
          })
    },
    // 跳转详情
    toDetail(item) {
      this.$router.push({
        path: '/NewsDetail',
        query: {
          id: item
        }
      })
    },
    goinf() {
      this.$router.push({path: '/Informations'})
    },
    goScheme() {
      this.$router.push({path: '/Scheme'})
    },
    changeValue(val) {
      this.value = val
    },
    // 核心产品跳转
    goYun() {
      this.$router.push({path: '/Product'})
    }

  },
  components: {
    TopNav,
    Footer,
    // Footer1
  }
}
</script>
<style lang="scss" scoped>
.home {
  padding-top: 80px;

  .first-1 {
    width: 100%;
    height: 700px;
    background-size: cover;
    //clear: both;
    .carousel-box {
      width: 100%;
      height: 700px;
      background: url("./../static/img/home/home-1.png") no-repeat center;

    }
  }

  .first {
    //padding-top: 60px;
    width: 100%;
    height: 700px;
    //background: url("./../static/image/index/bg2.png") no-repeat;
    background: url("./../static/img/home/home-1.png") no-repeat;
    background-size: cover;
    clear: both;

    .left {
      width: 510px;
      margin-top: 66px;
      float: left;

      .title {
        width: 456px;
        height: 46px;
        margin-bottom: 20px;
      }

      .todetail {
        width: 194px;
        height: 60px;
        margin-top: 36px;

        &:hover {
          cursor: pointer;
        }
      }

      p {
        width: 100%;
        letter-spacing: 1.2px;
        color: #fff;
        line-height: 36px;
      }
    }

    .right {
      position: relative;
      width: 640px;
      height: 500px;
      float: right;
      margin-top: 90px;

      .banner1 {
        position: absolute;
        width: 232px;
        height: 188px;
        left: 186px;
        top: 217px;
        z-index: 9;
      }

      .banner2 {
        position: absolute;
        width: 514px;
        height: 394px;
        left: 78px;
        top: 3px;
        z-index: 7;
        animation: gt 5s ease infinite;
      }

      .banner3 {
        position: absolute;
        left: 0;
        width: 610px;
        height: 490px;
      }
    }
  }

  .third {
    width: 100%;
    height: 810px;
    padding-top: 74px;
    padding-bottom: 116px;
    text-align: center;
    background: url("./../static/img/home/middle-1.png") no-repeat;

    .title {
      font-size: 42px;
      color: #ffffff;
    }

    .second_title {
      padding-top: 16px;
      padding-bottom: 80px;
      font-size: 28px;
      color: #b6b6c6;
    }

    .center_box {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: left;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 73px;

      .third-box {
        width: 270px;
        height: 240px;
        background-color: rgba(255, 255, 255, 0.2);
        //background-color: #586770;
        border-radius: 10px;
        color: white;
        display: inline-block;
        margin-right: 20px;
        margin-top: 40px;
        cursor: pointer;

        img {
          margin-top: 29px;
        }

        .third-box-txt {
          margin-top: 10px;
          margin-bottom: 8px;
          font-size: 22px;
          font-weight: 500;
        }

        .third-box-txt1 {
          padding: 0 20px;
          font-size: 16px;
          line-height: 25px;
        }

      }

      .third-box1 {
        width: 270px;
        height: 240px;
        color: white;
        display: inline-block;
        margin-right: 20px;
        margin-top: 40px;

        img {
          margin-top: 90px;
          cursor: pointer;
        }
      }
    }
  }

  .fourth {
    width: 100%;
    text-align: center;
    padding-top: 90px;
    padding-bottom: 90px;

    .title {
      font-size: 42px;
      color: #191e4a;
    }

    .second_title {
      padding-top: 16px;
      padding-bottom: 68px;
      font-size: 28px;
      color: #b6b6c6;
    }

    .center_box {
      overflow: hidden;
      height: 490px;
      vertical-align: middle;
      padding: 0 160px;
      clear: both;

      .center_img {
        // width: 400px;
        // height: 300px;
        width: 350px;
        height: 490px;
        // margin-top: 95px;
        transition: all 0.2s;
        background: url("http://www.huimei.com/images/about_03-xx.jpg") no-repeat center;

        // background-size: cover;
        &:hover {
          width: 350px;
          height: 490px;
          background: #333;
          background: url("http://www.huimei.com/images/about_03-zl2.jpg") no-repeat center;
          transition: all 0.2s;
        }
      }

      .left {
        float: left;
      }

      .right {
        float: right;
      }
    }
  }

  .five {
    width: 100%;
    height: 820px;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 100px;
    background: url("./../static/img/home/mimddle-2-bg.png") no-repeat center;
    background-size: cover;

    .inf {
      width: 1200px;
      height: 500px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      cursor: pointer;

      .inf-card {
        width: 560px;
        height: 480px;
        position: relative;

        .inf-card-time {
          width: 100px;
          height: 100px;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #AB7D3E;
          color: white;
          text-align: center;

          .inf-card-time1 {
            font-size: 16px;
            margin-top: 15px;
          }

          .inf-card-time2 {
            font-size: 36px;
            font-weight: 500;
          }
        }

        .inf-card-b1 {
          width: 500px;
          text-align: left;
          margin: 0 auto;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333333;
          margin-top: 29px;
          margin-bottom: 10px;
          font-weight: bolder;
        }

        .inf-card-b2 {
          width: 500px;
          text-align: left;
          margin: 0 auto;
          font-size: 14px;
          color: #666666;
          text-indent: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }


      }

      .inf-r {
        width: 600px;
        height: 480px;

        .inf-r1 {
          display: flex;
          justify-content: left;
          align-items: center;
          text-align: center;
          margin-bottom: 40px;

          .inf-r1-time {
            width: 133px;
            height: 133px;
            border: 5px solid white;
            margin-right: 20px;

            .inf-r1-time1 {
              font-size: 18px;
              margin-top: 20px;
            }

            .inf-r1-time2 {
              font-size: 40px;
            }
          }

          .inf-r1-txt {

            .inf-r1-txt1 {
              font-size: 20px;
              font-weight: bolder;
              color: #333333;
              width: 400px;
              overflow: hidden;
              text-align: left;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin: 0 auto;
              padding-bottom: 10px;
            }

            .inf-r1-txt2 {
              width: 400px;
              margin: 0 auto;
              font-size: 14px;
              text-align: left;
              text-indent: 20px;
              color: #666666;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              height: 78px;
            }
          }
        }

      }
    }

    .inf /deep/ .el-card__body {
      padding: 0;
    }
  }

  .six {
    width: 100%;
    //text-align: center;
    padding-top: 90px;
    padding-bottom: 60px;

    .title {
      font-size: 42px;
      color: #191e4a;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .second_title {
      padding-top: 16px;
      padding-bottom: 80px;
      font-size: 28px;
      color: #b6b6c6;
    }

    .news_view {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 0 20px;
      padding-bottom: 40px;
      overflow: hidden;

      .news_box {
        display: block;
        width: 540px;

        p {
          text-align: left;
        }

        .tip {
          position: relative;
          line-height: 22px;
          padding-left: 10px;
          margin-bottom: 20px;
          font-size: 20px;
          color: #191e4a;
          font-weight: bold;

          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            background: url("./../static/image/index/border.png") no-repeat center;
            background-size: cover;
          }
        }

        .news_img {
          width: 100%;
          height: 300px;
        }

        .title {
          font-size: 16px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 36px;
          padding-top: 8px;
        }

        .des {
          color: #666666;
          font-size: 12px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          padding-right: 76px;
          line-height: 20px;
        }

        .timer {
          padding-top: 2px;
          text-align: left;

          img {
            vertical-align: middle;
            width: 12px;
            height: 12px;
          }

          span {
            font-size: 12px;
            color: #cccccc;
            padding-left: 10px;
          }
        }
      }
    }

    .more {
      display: block;
      margin: 0 auto;
      width: 104px;
      height: 38px;
      background-image: url("./../static/image/index/more.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: all 200ms;

      &:hover {
        cursor: pointer;
        background-image: url("./../static/image/index/more1.png");
      }
    }
  }
}

/deep/ .el-carousel__indicators {
  display: none;
  position: absolute;
  top: 560px !important;

  .el-carousel__button {
    display: none;
    height: 5px;
    border-radius: 2px;
  }
}

.top-bg-all {
  position: relative;
  top: -70px;
  width: 100%;
  z-index: 99;

  .top-bg {
    width: 1400px;
    height: 246px;
    background: url("./../static/img/home/top-img-upbg.png") no-repeat;
    z-index: 99;
    margin: 0 auto;

    .top-bg-text {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;

      .top-bg-text-1 {
        margin-top: 20px;
        padding-top: 71px;
        padding-bottom: 60px;
        padding-right: 94px;
        border-right: 1px solid #F0F0F0;
      }

      .top-bg-text-2 {
        padding-left: 64px;
        padding-top: 32px;
        padding-right: 50px;

        p {
          font-size: 24px;
          color: #333333;
          text-align: left;
          font-weight: bolder;
        }

        div {
          font-size: 15px;
          color: #777;
          text-align: left;
          margin-top: 18px;
          text-indent: 30px
        }
      }
    }
  }

  .top-bg-2 {
    width: 100%;
    height: 246px;
    z-index: 999;
    padding-top: 76px;
    margin: 0 auto;
  }

}

.four {
  display: flex;
  justify-content: space-between;
  align-items: end;

  .card {
    width: 1420px;
    height: 489px;
    margin-top: 120px;
    position: relative;
    overflow: unset !important;
    margin-bottom: 120px;
    //margin-left: calc(100% - 1560px);
    .card-1 {
      position: absolute;
      top: 44px;
      left: -140px;
    }

    .card-line {
      height: 400px;
      border-right: 1px solid #ddd;
      position: absolute;
      top: 40px;
      left: 621px;
    }

    .four-box {
      width: 439px !important;
      display: inline-block;
      box-sizing: border-box;
      padding: 42px 0px 42px 42px;
      text-align: left;

      .four-box-txt {
        margin-left: 23px;

        .four-box-txt1 {
          color: #AB7D3E;
          font-size: 24px;
          font-weight: 500;
          margin-top: 20px;
        }

        .four-box-txt2 {
          color: #666;
          font-size: 16px;
          margin-top: 10px;
        }
      }

    }

    .four-box1 {
      //border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }

    .four-box2 {
      border-bottom: 1px solid #ddd;
      //border-left: 1px solid #ddd;

    }

    .four-box3 {
      //border-top: 1px solid #ddd;

      //border-right: 1px solid #ddd;

    }

    .four-box4 {
      //border-top: 1px solid #ddd;
      //border-left: 1px solid #ddd;

    }
  }
}

.node-img {
  width: 100%;
  height: 20px;
  text-align: center;
}

.node-img1 {
  width: 100%;
  height: 0px;
  text-align: center;
  position: relative;
  top: -23px;

}

.last {
  width: 100%;
  text-align: center;
  //padding-top: 50px;
  .last-name {
    width: 420px;
    margin: 0 auto;

    .last-name1 {
      width: 418px;
      border: 2px solid #AB7D3E;
      color: #AB7D3E;
      margin-bottom: 50px;
      cursor: pointer;
      box-sizing: border-box;

      div {
        display: inline-block;
        width: 138px;
        padding: 10px 20px;
        box-sizing: border-box;

      }
    }

    .last-name-active {
      background-color: #AB7D3E;
      color: white;
      padding: 10px 20px;

    }
  }

  .friends-box {
    width: 1200px;
    height: 300px;
    margin: 0 auto;
    margin-bottom: 30px;

    .friends-card {
      width: 362px;
      height: 230px;
      display: inline-block;
      //margin-right: 30px;
      font-size: 16px;
      border-radius: 15px;

      .friends-card-b1 {
        margin-top: 10px;
        font-size: 16px;
        color: #666666;
        text-align: left;
        padding-left: 20px;
      }
    }
    .friends-card-mid{
      margin: 0 46px;
    }

  }

  .friends-box /deep/ .el-card__body {
    padding: 0;
  }
}

@media screen and (max-width: 1400px) {
  .four {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .card {
      width: 1120px;
      height: 489px;
      margin-top: 120px;
      position: relative;
      overflow: unset !important;
      margin-bottom: 120px;
      //margin-left: calc(100% - 1560px);
      .card-1 {
        position: absolute;
        top: 44px;
        left: -140px;
      }

      .card-line {
        height: 400px;
        border-right: 1px solid #ddd;
        position: absolute;
        top: 40px;
        left: 621px;
      }

      .four-box {
        width: 439px !important;
        display: inline-block;
        box-sizing: border-box;
        padding: 42px 0px 42px 42px;
        text-align: left;

        .four-box-txt {
          margin-left: 23px;

          .four-box-txt1 {
            color: #AB7D3E;
            font-size: 24px;
            font-weight: 500;
            margin-top: 20px;
          }

          .four-box-txt2 {
            color: #666;
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }

      .four-box1 {
        border-bottom: 1px solid #ddd;
      }

      .four-box2 {
        border-bottom: 1px solid #ddd;
      }
    }
  }
  .top-bg-all {
    position: relative;
    top: -70px;
    width: 100%;
    z-index: 99;

    .top-bg {
      width: 1200px;
      height: 246px;
      background: url("./../static/img/home/top-img-upbg.png") no-repeat;
      z-index: 99;
      margin: 0 auto;

      .top-bg-text {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        .top-bg-text-1 {
          margin-top: 20px;
          padding-top: 71px;
          padding-bottom: 60px;
          padding-right: 94px;
          padding-left: 50px;
          border-right: 1px solid #F0F0F0;
        }

        .top-bg-text-2 {
          padding-left: 34px;
          padding-top: 48px;
          padding-right: 30px;

          p {
            font-size: 24px;
            color: #333333;
            text-align: left;
            font-weight: bolder;
          }

          div {
            font-size: 16px;
            color: #999;
            text-align: left;
            margin-top: 18px;
          }
        }
      }
    }

    .top-bg-2 {
      width: 100%;
      height: 246px;
      z-index: 999;
      padding-top: 76px;
      margin: 0 auto;
    }

  }

}

.fives {
  width: 100%;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 100px;
  //background: url("./../static/image/index/bg.png") no-repeat center;
  //background-size: cover;
  background-color: white;

  .title {
    font-size: 42px;
    color: #191e4a;
  }

  .second_title {
    padding-top: 16px;
    padding-bottom: 50px;
    font-size: 28px;
    color: #b6b6c6;
  }

  .img_box {
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    // justify-items: center;
    width: 100%;
    height: 200px;
    margin-bottom: 40px;

    .img_list {
      position: relative;
      width: 350px;
      height: 200px;

      &:first-of-type {
        margin-right: 100px;
      }

      img {
        width: 100%;
        height: 100%;
        background: #f19103;
      }
    }

    p {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 350px;
      height: 40px;
      line-height: 40px;
      background: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      color: #fff;
      padding-left: 10px;
      text-align: left;
    }
  }

  .swiper_box {
    padding: 0 80px;

    .swiper_box1 {
      width: 30px;
      height: 240px;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 100;
    }

    .swiper-slide {
      text-align: left;
      padding-left: 90px;
    }

    .swiper_button {
      width: 30px;
      height: 52px;
      margin-top: -26px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: all 0.2s;

      &.swiper-button-prev {
        background-image: url("./../static/image/index/left.png") !important;

        &:hover {
          background-image: url("./../static/image/index/left1.png") !important;
        }
      }

      &.swiper-button-next {
        background-image: url("./../static/image/index/right.png") !important;

        &:hover {
          background-image: url("./../static/image/index/right1.png") !important;
        }
      }
    }

    .slide_img {
      display: inline-block;
      width: 280px;
      height: 100px;
      background: #fff;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid #EBEEF5;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

      &:nth-of-type(3n + 1) {
        margin-left: 0;
      }

      &.active {
        margin-bottom: 0;
      }

      & img {
        display: inline-block;
        text-align: center;
        margin-top: 30px;
        width: 204px;
        height: 42px;
      }
    }
  }
}

.bottom-img {
  width: 920px;
  margin: 0 auto;
  text-align: left;
}

.slide_img {
  display: inline-block;
  width: 280px;
  height: 100px;
  background: #fff;
  border-radius: 10px;
  margin-left: 40px;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #EBEEF5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);

  &:nth-of-type(3n + 1) {
    margin-left: 0;
  }

  &.active {
    margin-bottom: 0;
  }

  & img {
    display: inline-block;
    text-align: center;
    margin-top: 30px;
    width: 204px;
    height: 42px;
  }
}
 /deep/ .el-carousel__arrow{
  color: #dcdcdc !important;
  background-color: transparent !important;
  font-weight: bolder !important;
  font-size: 60px;
  top: 40%;
 }
/deep/ .el-carousel__arrow:hover{
  color: #9c9c9c !important;
  background-color: transparent !important;
  font-weight: bolder !important;
  font-size: 60px;
  top: 40%;
}
 /deep/ .el-carousel__arrow i{
   font-weight: 900;
 }
.six {
  width: 100%;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 60px;

  .title {
    font-size: 42px;
    color: #191e4a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .second_title {
    padding-top: 16px;
    padding-bottom: 80px;
    font-size: 28px;
    color: #b6b6c6;
  }

  .news_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    padding-bottom: 40px;
    overflow: hidden;

    .news_box {
      display: block;
      width: 540px;

      p {
        text-align: left;
      }

      .tip {
        position: relative;
        line-height: 22px;
        padding-left: 10px;
        margin-bottom: 20px;
        font-size: 20px;
        color: #191e4a;
        font-weight: bold;

        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 4px;
          height: 22px;
          background: url("./../static/image/index/border.png") no-repeat center;
          background-size: cover;
        }
      }

      .news_img {
        width: 100%;
        height: 300px;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        line-height: 36px;
        padding-top: 8px;
      }

      .des {
        color: #666666;
        font-size: 12px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-right: 76px;
        line-height: 20px;
      }

      .timer {
        padding-top: 2px;
        text-align: left;

        img {
          vertical-align: middle;
          width: 12px;
          height: 12px;
        }

        span {
          font-size: 12px;
          color: #cccccc;
          padding-left: 10px;
        }
      }
    }
  }

  .more {
    display: block;
    margin: 0 auto;
    width: 104px;
    height: 38px;
    background-image: url("./../static/image/index/more.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: all 200ms;

    &:hover {
      cursor: pointer;
      background-image: url("./../static/image/index/more1.png");
    }
  }
}

</style>
